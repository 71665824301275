import invariant from 'invariant';

import { configString } from 'config/appConfigUtils';
import { ActionDateFacetValue } from 'modules/search/constants/searchFacets/actionDateFacet';
import { SortType } from 'store/mainSearch/MainStoreMainSearchState';
import { isNotEmpty } from 'utils/functional';

type Args = {
  actionDate: ActionDateFacetValue | null | undefined;
  sort: SortType;
};

export function searchAlgoliaVolopsIndex({ actionDate, sort }: Args) {
  const volopsIndexName = configString('algolia', 'volopsIndexName');
  invariant(isNotEmpty(volopsIndexName), 'volopsIndexName cannot be empty');

  if (actionDate && (actionDate.endsGT || actionDate.startsLT)) {
    return `${volopsIndexName}-starts-asc`;
  }

  if (sort === 'relevance') return volopsIndexName;

  return `${volopsIndexName}-published-desc`;
}
